import EventServiceV2 from '@/lib/event-service-v2';
import ProvinceStateService from '@/lib/province-state-view-service';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import CategoryService from '@/lib/category-service';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { getField, updateField } from 'vuex-map-fields';

const editRaffle = {
  state: () => ({
    raffle: {},
    basicForm: {
      name: '',
      logoUrl: '',
      uploadError: false,
      uploadSuccess: false,
      description: '',
      rules: '',
      licenseNumber: '',
      licenseUrl: null,
      organizationId: '',
      shortlink: '',
      dropdownLabel: '',
      provinceOptions: null,
      province: '',
      category: '',
      salesRepId: '',
      startDate: '',
      startTime: '',
      endDate: '',
      drawDate: '',
      timeZone: '',
      minEndDate: '',
      minDrawDate: '',
      endTime: '',
      drawTime: '',
      frequency: '',
      drawLocation: '',
      showLiveRaffles: false,
      loading: false,
      showLicenseUpload: false,
      startDateTime: null,
      endDateTime: null,
      drawDateTime: null
    },
    provinceStateView: { dropdownLabel: 'Province', dropdownOptions: [] },
    country: '',
    categoryOptions: null,
    emailForm: {
      emailTicketBody: '',
      emailTicketFooter: ''
    },
    feesForm: {
      raffleboxFeePercent: '',
      raffleboxFeeFlatRateDollars: '',
      raffleboxOrderFeeDollars: '',
      processingFeePercent: ''
    },
    inSupportOfForm: {
      supportStatsActive: false,
      supportStatsForceSelectionEnabled: false,
      supportStatsShow: false,
      hideEntireGroup: false,
      supportStatsLabel: ''
    },
    settings: {
      fractionalJackpotPercent: null,
      allowInVenueSales: null,
      allowSecondaryName: null,
      geoLocation: null,
      hidePot: null,
      hideEntireGroup: null,
      hideSocialLinks: null,
      hidePrizeList: null,
      hideWinnersList: null,
      pixelCode: null,
      gtmCode: null,
      customJackpotEnabled: false,
      removeCashButton: null,
      removeCreditButton: null,
      removeDebitButton: null,
      jackpotStatement: null,
      jackpotSubTitle: null,
      maxOrdersPerCustomer: 0,
      whiteLabel: {
        enabled: false,
        buttonColor: '',
        checkoutBannerUrl: ''
      },
      grandPrizeDescription: null,
      grandPrizeTitle: null,
      compactPrinting: false,
      hideCountDownTimer: false,
      ivLogoUrl: null,
      ticketLimit: 0,
      tickerQrCode: false,
      invenuePhone: null,
      ivRequireFirstLastName: false,
      ivRequireEmail: false,
      hidePurchaseTab: false,
      donationEnabled: null
    },
    tickerForm: {
      tickerHeader: '',
      tickerFooter: null,
      tickerUrl: null,
      tickerLogo: null
    },
    invenueForm: {
      ticketFooterQRCode: null,
      ticketFooterQRCodeImage: null,
      ticketFooterImage: null,
      invenueTicketFooter: null,
      invenueFooterImage: null,
      invenueImageUploadSuccess: null,
      invenueImageUploadError: null,
      errorMessage: null,
      showTicketFooterQRCode: false,
      ticketFooterQRCodeImageUploading: false,
      invenueFooterImageUploadError: null,
      invenueFooterImageUploadSuccess: null
    },
    advancedForm: {
      maxJackpot: null,
      startingPot: null,
      minimumJackpotDisplay: null,
      startingTicketNumber: null
    },
    rbAdminForm: {
      rulesEnabled: false,
      postalCodeCheck: false,
      drawType: null,
      statementDescriptor: null,
      parentEvent: null,
      ticketNumbersShow: false,
      drawTypeOptions: [
        { value: 'barrel', text: 'Print Tickets' },
        { value: 'rng', text: 'Random Number Generator' }
      ]
    },
    status: 'active',
    datesValid: true
  }),
  getters: {
    getBasicData: (state) => {
      return {
        name: state.raffle.name,
        logoUrl: state.raffle.logoUrl,
        uploadError: state.raffle.raffleImageUploadError,
        uploadSuccess: state.raffle.raffleImageUploadSuccess,
        licenseNumber: String(state.raffle.licenseNumber),
        licenseUrl: state.raffle.licenseUrl,
        organizationId: state.raffle.organizationId,
        dropdownLabel: state.provinceStateView.dropdownLabel,
        provinceOptions: state.provinceStateView.dropdownOptions,
        categoryOptions: state.categoryOptions,
        timeZone: state.raffle.timeZone,
        minEndDate: state.raffle.startDate,
        minDrawDate: state.raffle.endDate || state.raffle.startDate,
        loading: state.basicForm.loading,
        showLicenseUpload: state.basicForm.showLicenseUpload
      };
    },
    getBasicForm: (state) => state.basicForm,
    getEmailForm: (state) => state.emailForm,
    getFeesForm: (state) => state.feesForm,
    getEventId: (state) => state.raffle.id,
    getInSupportOfForm: (state) => state.inSupportOfForm,
    getSettings: (state) => state.settings,
    getTickerForm: (state) => state.tickerForm,
    getInvenueForm: (state) => state.invenueForm,
    getAdvancedForm: (state) => state.advancedForm,
    getRaffle: (state) => state.raffle,
    getRbAdminForm: (state) => state.rbAdminForm,
    getStatus: (state) => state.raffle.status,
    getDatesValid: (state) => state.datesValid,
    getField
  },
  actions: {
    fetchEvent: async ({ commit, dispatch, state }, eventId) => {
      state.basicForm.loading = true;
      try {
        const result = await EventServiceV2.retrieveEvent(eventId);
        commit('SET_RAFFLE', result);

        let startDateTime = { date: null, time: null };
        if (result.startDate) {
          startDateTime = await dispatch('getSimplifiedDateTime', {
            date: result.startDate,
            timeZone: result.timeZone
          });
        }

        const endDateTime = await dispatch('getSimplifiedDateTime', {
          date: result.endDate,
          timeZone: result.timeZone
        });

        const drawDateTime = await dispatch('getSimplifiedDateTime', {
          date: result.drawDate,
          timeZone: result.timeZone
        });

        const formattedResult = {
          ...result,
          startDate: startDateTime.formattedDate,
          startTime: startDateTime.formattedTime,
          endDate: endDateTime.formattedDate,
          endTime: endDateTime.formattedTime,
          drawDate: drawDateTime.formattedDate,
          drawTime: drawDateTime.formattedTime,
          startingPot: result.startingPotCents / 100,
          minimumJackpotDisplay: result.minimumJackpotDisplayCents / 100
        };

        dispatch('setBasicForm', formattedResult);
        dispatch('setEmailForm', result);
        dispatch('setFeesForm', result);
        dispatch('setInSupportOfForm', result);
        dispatch('setSettings', result.settings);
        dispatch('setTickerForm', result);
        dispatch('setInvenueForm', result);
        dispatch('setAdvancedForm', formattedResult);
        dispatch('setRbAdminForm', result);
      } catch (error) {
        console.log(error);
      }
    },
    fetchUserOrganization: async ({ commit, rootState }) => {
      try {
        const result = await OrganizationServiceV2.retrieveOrganization(rootState.user.organizationUuid);
        commit('SET_ORGANIZATION_COUNTRY', result);
      } catch (error) {
        console.log(error);
      }
    },
    fetchProvinceStateView: async ({ commit, state }) => {
      try {
        const result = await ProvinceStateService.getEditRaffleModalView(state.country);
        commit('SET_PROVINCE_STATE_VIEW', result);
      } catch (error) {
        console.log(error);
      }
    },
    fetchCategoryOptions: async ({ commit }) => {
      try {
        const result = await CategoryService.listCategories();
        commit('SET_CATEGORY_OPTIONS', result);
      } catch (error) {
        console.log(error);
      }
    },
    setBasicForm: async ({ commit }, event) => {
      commit('SET_BASIC_FORM', event);
    },
    setRaffleDates: async ({ commit }) => {
      commit('SET_RAFFLE_DATES');
    },
    setEmailForm: async ({ commit }, event) => {
      commit('SET_EMAIL_FORM', event);
    },
    setFeesForm: async ({ commit }, event) => {
      commit('SET_FEES_FORM', event);
    },
    setInSupportOfForm: async ({ commit }, event) => {
      commit('SET_IN_SUPPORT_OF_FORM', event);
    },
    updateBasicForm: async ({ commit }, event) => {
      commit('UPDATE_BASIC_FORM', event);
    },
    setShowLicenseUpload: async ({ commit }, event) => {
      commit('SET_SHOW_LICENSE_UPLOAD', event);
    },
    setLicenseUrl: async ({ commit }, event) => {
      commit('SET_LICENSE_URL', event);
    },
    setSettings: async ({ commit }, event) => {
      commit('SET_SETTINGS', event);
    },
    setTickerForm: async ({ commit }, event) => {
      commit('SET_TICKER_FORM', event);
    },
    setInvenueForm: async ({ commit }, event) => {
      commit('SET_IN_VENUE_FORM', event);
    },
    setAdvancedForm: async ({ commit }, event) => {
      commit('SET_ADVANCED_FORM', event);
    },
    setRbAdminForm: async ({ commit }, event) => {
      commit('SET_RB_ADMIN_FORM', event);
    },
    setStatus: async ({ commit }, status) => {
      commit('SET_STATUS', status);
    },
    setDatesValid: async ({ commit }, datesValid) => {
      commit('SET_DATES_VALID', datesValid);
    },
    getSimplifiedDateTime(_context, payload) {
      const { date, timeZone } = payload;
      const isoDate = parseISO(date);
      const zonedDate = utcToZonedTime(isoDate, timeZone);

      const formattedDate = format(zonedDate, 'yyyy-MM-dd', { timeZone: timeZone });
      const formattedTime = format(zonedDate, 'HH:mm:ss', { timeZone: timeZone });

      return {
        formattedDate,
        formattedTime
      };
    }
  },
  mutations: {
    SET_RAFFLE: (state, event) => {
      state.raffle = event;
    },
    SET_BASIC_FORM: (state, event) => {
      state.basicForm.name = event.name;
      state.basicForm.logoUrl = event.logoUrl;
      state.basicForm.uploadError = event.raffleImageUploadError;
      state.basicForm.uploadSuccess = event.raffleImageUploadSuccess;
      state.basicForm.description = event.description;
      state.basicForm.rules = event.rules;
      state.basicForm.licenseNumber = event.licenseNumber;
      state.basicForm.licenseUrl = event.licenseUrl;
      state.basicForm.shortlink = event.shortlink;
      state.basicForm.province = event.province;
      state.basicForm.category = event.category;
      state.basicForm.salesRepId = event.salesRepId;
      state.basicForm.startDate = event.startDate;
      state.basicForm.timeZone = event.timeZone;
      state.basicForm.endDate = event.endDate;
      state.basicForm.drawDate = event.drawDate;
      state.basicForm.frequency = event.frequency;
      state.basicForm.drawLocation = event.drawLocation;
      state.basicForm.showLiveRaffles = event.show || event.showLiveRaffles || false;
      state.basicForm.startTime = event.startTime;
      state.basicForm.endTime = event.endTime;
      state.basicForm.drawTime = event.drawTime;
    },
    SET_LICENSE_URL: (state, event) => {
      state.basicForm.licenseUrl = event.licenseUrl;
    },
    SET_RAFFLE_DATES: (state) => {
      state.basicForm.startDateTime =
        state.basicForm.startDate && state.basicForm.startTime
          ? zonedTimeToUtc(`${state.basicForm.startDate} ${state.basicForm.startTime}`, state.basicForm.timeZone)
          : undefined;
      state.basicForm.endDateTime = zonedTimeToUtc(
        `${state.basicForm.endDate} ${state.basicForm.endTime}`,
        state.basicForm.timeZone
      );
      state.basicForm.drawDateTime = zonedTimeToUtc(
        `${state.basicForm.drawDate} ${state.basicForm.drawTime}`,
        state.basicForm.timeZone
      );
    },
    SET_EMAIL_FORM: (state, event) => {
      state.emailForm.emailTicketBody = event.emailTicketBody;
      state.emailForm.emailTicketFooter = event.emailTicketFooter;
    },
    SET_FEES_FORM: (state, event) => {
      state.feesForm.raffleboxFeePercent = parseFloat(event.raffleboxFeePercent).toFixed(2);
      state.feesForm.raffleboxOrderFeeDollars = event.raffleboxOrderFeeCents
        ? Number(event.raffleboxOrderFeeCents / 100.0 || 0)
        : Number(event.raffleboxOrderFeeDollars);
      state.feesForm.processingFeePercent = parseFloat(event.processingFeePercent).toFixed(2);
      state.feesForm.raffleboxFeeFlatRateDollars = event.raffleboxFlatRateCents
        ? Number(event.raffleboxFeeFlatRateCents / 100.0)
        : Number(event.raffleboxFeeFlatRateDollars);
    },
    SET_RAFFLEBOX_FEE_PERCENT: (state, percent) => {
      state.feesForm.raffleboxFeePercent = parseFloat(percent).toFixed(2);
    },
    SET_RAFFLEBOX_FEE_FLAT_RATE_DOLLARS: (state, dollars) => {
      state.feesForm.raffleboxFeeFlatRateDollars = parseFloat(dollars).toFixed(2);
    },
    SET_IN_SUPPORT_OF_FORM: (state, event) => {
      state.inSupportOfForm.supportStatsActive = event.supportStatsActive
        ? event.supportStatsActive
        : state.supportStatsActive;
      state.inSupportOfForm.supportStatsForceSelectionEnabled = event.supportStatsForceSelectionEnabled;
      state.inSupportOfForm.supportStatsShow = event.supportStatsShow;
      state.inSupportOfForm.supportStatsLabel = event.supportStatsLabel;
    },
    SET_TICKER_FORM: (state, event) => {
      state.tickerForm.tickerHeader = event.tickerHeader;
      state.tickerForm.tickerFooter = event.tickerFooter;
      state.tickerForm.tickerUrl = event.tickerUrl;
      state.tickerForm.tickerLogo = event.tickerLogo;
    },
    SET_IN_VENUE_FORM: (state, event) => {
      state.invenueForm.ticketFooterQRCode = event.ticketFooterQRCode ?? '';
      state.invenueForm.ticketFooterQRCodeImage = event.ticketFooterQRCodeImage ?? '';
      state.invenueForm.ticketFooterImage = event.ticketFooterImage;
      state.invenueForm.invenueTicketFooter = event.invenueTicketFooter;
      state.invenueForm.invenueFooterImage = event.invenueFooterImage;
      state.invenueForm.invenueImageUploadSuccess = event.invenueImageUploadSuccess;
      state.invenueForm.invenueImageUploadError = event.invenueImageUploadError;
      state.invenueForm.errorMessage = event.errorMessage;
      state.invenueForm.showTicketFooterQRCode = event.showTicketFooterQRCode;
      state.invenueForm.ticketFooterQRCodeImageUploading = event.ticketFooterQRCodeImageUploading;
      state.invenueForm.invenueFooterImageUploadError = event.invenueFooterImageUploadError;
      state.invenueForm.invenueFooterImageUploadSuccess = event.invenueFooterImageUploadSuccess;
    },
    SET_ADVANCED_FORM: (state, event) => {
      state.advancedForm.maxJackpot = event.maxJackpot ? Number(event.maxJackpot) : 0;
      state.advancedForm.startingTicketNumber = event.startingTicketNumber;
      state.advancedForm.startingPot = event.startingPot;
      state.advancedForm.minimumJackpotDisplay = event.minimumJackpotDisplay;
    },
    SET_ORGANIZATION_COUNTRY: (state, organization) => {
      state.country = organization.country;
      state.basicForm.loading = false;
    },
    SET_PROVINCE_STATE_VIEW: (state, provinceStateView) => {
      state.provinceStateView = provinceStateView;
    },
    SET_CATEGORY_OPTIONS: (state, categoryOptions) => {
      state.categoryOptions = categoryOptions;
    },
    SET_SHOW_LICENSE_UPLOAD: (state, showLicenseUpload) => {
      state.basicForm.showLicenseUpload = showLicenseUpload;
    },
    SET_SETTINGS: (state, event) => {
      state.settings.fractionalJackpotPercent =
        event.fractionalJackpotPercent && event.fractionalJackpotPercent % 1 !== 0
          ? Number(event.fractionalJackpotPercent).toFixed(2)
          : event.fractionalJackpotPercent;
      state.settings.allowInVenueSales = event.allowInVenueSales;
      state.settings.geoLocation = event.geoLocation;
      state.settings.hidePot = event.hidePot;
      state.settings.hideEntireGroup = event.hideEntireGroup;
      state.settings.hideSocialLinks = event.hideSocialLinks;
      state.settings.hidePrizeList = event.hidePrizeList;
      state.settings.hideWinnersList = event.hideWinnersList;
      state.settings.pixelCode = event.pixelCode;
      state.settings.gtmCode = event.gtmCode;
      state.settings.customJackpotEnabled = event.customJackpotEnabled;
      state.settings.removeCashButton = event.removeCashButton;
      state.settings.removeCreditButton = event.removeCreditButton;
      state.settings.removeDebitButton = event.removeDebitButton;
      state.settings.jackpotStatement = event.jackpotStatement;
      state.settings.jackpotSubTitle = event.jackpotSubTitle;
      state.settings.maxOrdersPerCustomer = event.maxOrdersPerCustomer;
      state.settings.whiteLabel.enabled = event.whiteLabel.enabled;
      state.settings.whiteLabel.buttonColor = event.whiteLabel.buttonColor ? event.whiteLabel.buttonColor : '#5db966';
      state.settings.whiteLabel.checkoutBannerUrl = event.whiteLabel.checkoutBannerUrl;
      state.settings.grandPrizeDescription = event.grandPrizeDescription;
      state.settings.grandPrizeTitle = event.grandPrizeTitle;
      state.settings.compactPrinting = event.compactPrinting;
      state.settings.hideCountDownTimer = event.hideCountDownTimer;
      state.settings.ivLogoUrl = event.ivLogoUrl;
      state.settings.tickerQrCode = event.tickerQrCode;
      state.settings.invenuePhone = event.invenuePhone;
      state.settings.ivRequireFirstLastName = event.ivRequireFirstLastName;
      state.settings.ivRequireEmail = event.ivRequireEmail;
      state.settings.allowSecondaryName = event.allowSecondaryName;
      state.settings.ticketLimit = event.ticketLimit ? event.ticketLimit : 0;
      state.settings.hidePurchaseTab = event.hidePurchaseTab;
      state.settings.donationEnabled = event.donationEnabled;
    },
    SET_RB_ADMIN_FORM: (state, event) => {
      state.rbAdminForm.rulesEnabled = event.rulesEnabled;
      state.rbAdminForm.postalCodeCheck = !event.disablePostalCheckV2;
      state.rbAdminForm.drawType = event.drawType;
      state.rbAdminForm.statementDescriptor = event.statementDescriptor;
      state.rbAdminForm.parentEvent = event.parentEvent;
      state.rbAdminForm.ticketNumbersShow = event.ticketNumbersShow;
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    },
    SET_DATES_VALID: (state, datesValid) => {
      state.datesValid = datesValid;
    },
    updateField
  }
};

export default editRaffle;
