<template>
  <div>
    <b-form-group label-for="input-license-number" :invalid-feedback="veeErrors.first('input-license-number')">
      <template slot="label"
        >Raffle License Number
        <span
          ><sup
            v-b-tooltip.hover.top="
              'If you do not have a license you can provide it later. It will be required before your Raffle is made live.'
            "
          >
            <span class="pl-2 fas fa-info-circle"></span> </sup></span
      ></template>
      <b-form-input
        name="input-license-number"
        :value="value"
        @input="onInput"
        v-validate.bail="`${this.required ? 'required' : ''}|min:5`"
        :state="validateState()"
        aria-describedby="input-license-number"
        data-vv-as="Raffle License Number"
        trim
      />
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validateState() {
      if (
        this.veeFields['input-license-number'] &&
        (this.veeFields['input-license-number'].dirty || this.veeFields['input-license-number'].validated)
      ) {
        return !this.veeErrors.has('input-license-number');
      }

      return null;
    },
    isValid() {
      return this.veeFields['input-license-number']['invalid'] === false;
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
