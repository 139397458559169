import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import VueMeta from 'vue-meta';
import { initializeAuth, getFirebaseAuth, SessionUser } from '@rafflebox-technologies-inc/auth-service-sdk';
import { validatePostal } from '@rafflebox-technologies-inc/rafflebox-locations';
import * as raffleblocks from '@rafflebox-technologies-inc/raffleblocks';
import '@rafflebox-technologies-inc/raffleblocks/dist/style.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VeeValidate, { Validator } from 'vee-validate';
import passwordValidator from 'password-validator';
import JsonViewer from 'vue-json-viewer';
import 'v-markdown-editor/dist/v-markdown-editor.css';
import Editor from 'v-markdown-editor';
import mixin from './mixin.js';
import cronstrue from 'cronstrue';
import { parseExpression } from 'cron-parser';
import VueTheMask from 'vue-the-mask';
import VueNumber from 'vue-number-animation';
import '@rafflebox-technologies-inc/rafflebox-core-theme/dist/styles.min.css';
import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';

import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

import config from './config';
import App from './App.vue';
import Default from './layouts/Default.vue';
import NoSidebar from './layouts/NoSidebar.vue';
import Blank from './layouts/Blank.vue';
import router from './router';
import store from '@/store';
import axios from '@/axios';
import fontAwesome from './font-awesome.js';

import '@/assets/css/tailwind.css';

console.log(`Loading dashboard - ${config.ENV}`);

initializeAuth(config.ENV, axios);

// Must be called after `initializeAuth`
getFirebaseAuth().onAuthStateChanged(async (user) => {
  if (user) {
    const idTokenResult = await user?.getIdTokenResult();
    const sessionUser = new SessionUser(idTokenResult);
    store.dispatch('setUser', sessionUser);
  }
});

fontAwesome(Vue);
Vue.component('Blank', Blank);
Vue.component('Default', Default);
Vue.component('NoSidebar', NoSidebar);

// Dynamically load in all components from raffleblocks
const modules = raffleblocks.default;
Object.entries(modules).forEach(([path, module]) => {
  // Get the component name from it's path
  const componentName = upperFirst(
    camelCase(
      path
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );

  Vue.component(`${componentName}`, module.default);
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta);
Vue.use(JsonViewer);
Vue.use(Editor);
Vue.use(VueTheMask);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors',
  events: 'input|blur'
});

Vue.use(VueTrumbowyg);
Vue.use(VueNumber);

if (config.GOOGLE_TAG_MANAGER_CONTAINER_ID) {
  Vue.use(VueGtm, {
    id: config.GOOGLE_TAG_MANAGER_CONTAINER_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  });
}

Vue.mixin(mixin);

// Custom password validation set globally
const passwordSchema = new passwordValidator();

// prettier-ignore
passwordSchema
  .is().min(8)
  .is().max(100)
  .has().uppercase()
  .has().lowercase()
  .has().digits();

Validator.extend('password', {
  getMessage: (field) => 'The ' + field + ' field must contain minimum 8 characters, uppercase, lowercase and a number',
  validate: (value) => passwordSchema.validate(value)
});

Validator.extend('phoneNumber', {
  getMessage: (field) => 'The ' + field + ' field must contain 10 digits.',
  validate: (value) => {
    const cleanPhone = value.replace(/[-+()\s]/g, '');
    if (cleanPhone.length !== 10) {
      return false;
    } else {
      return true;
    }
  }
});

const paramNames = ['province'];

Validator.extend('postal', {
  paramNames,
  getMessage: () => 'The postal code must be from within the province.',
  validate: (postalCode, { province }) => {
    return validatePostal(province, postalCode);
  }
});

// Custom error message on login username field
const dict = {
  custom: {
    username: {
      required: 'The email address is required'
    }
  }
};
Validator.localize('en', dict);

// custom cron validation
Validator.extend('cron', {
  getMessage: (field) => 'The ' + field + ' field must be a valid cron expression',
  validate: (value) => {
    try {
      parseExpression(value);

      return cronstrue.toString(value);
    } catch (error) {
      return false;
    }
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
