import OrganizationService from '@/lib/organization-service-v2';

const localStorage = window.localStorage;

const organizations = {
  state: () => {
    return {
      allOrganizations: localStorage.getItem('organizations.allOrganizations')
        ? JSON.parse(localStorage.getItem('organizations.allOrganizations'))
        : null
    };
  },

  actions: {
    loadAllOrganizations: async ({ commit }) => {
      const organizations = await OrganizationService.retrieveAllOrganizations();

      commit('SET_ALL_ORGANIZATIONS', organizations);
    },
    clearAllOrganizations: async ({ commit }) => {
      commit('CLEAR_ALL_ORGANIZATIONS');
    }
  },

  mutations: {
    SET_ALL_ORGANIZATIONS: (state, organizations) => {
      state.allOrganizations = organizations;
      localStorage.setItem('organizations.allOrganizations', JSON.stringify(organizations));
    },
    CLEAR_ALL_ORGANIZATIONS: (state) => {
      state.allOrganizations = null;
      localStorage.removeItem('organizations.allOrganizations');
    }
  }
};

export default organizations;
