var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav',{class:{ 'sidebar-bg': !_vm.hideNav },attrs:{"id":"sidebar"}},[_c('div',{staticClass:"flex flex-col h-100 list-unstyled components",class:{ 'hide-nav': _vm.hideNav }},[_vm._m(0),(_vm.canViewDashboard)?_c('router-link',{attrs:{"to":"/","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Dashboard","active":isActive,"navigate":navigate,"href":href,"icon":"fa-tachometer-alt"}})]}}],null,false,4068805632)}):_vm._e(),(_vm.showAdmin)?_c('router-link',{attrs:{"to":"/admin","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Admin","icon":"fa-shield-alt","active":isActive,"navigate":navigate,"href":href}})]}}],null,false,3200756110)}):_vm._e(),(_vm.showRaffles)?_c('router-link',{attrs:{"to":"/raffles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Raffles","href":href,"active":isActive,"navigate":navigate,"icon":"fa-tags"}})]}}],null,false,4214826466)}):_vm._e(),(_vm.canViewGoldrushRaffles)?_c('router-link',{attrs:{"to":"/goldrush"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Goldrush","href":href,"active":isActive,"navigate":navigate,"icon":"fa-ticket-alt"}})]}}],null,false,13512294)}):_vm._e(),(_vm.canViewGoldrushV2)?_c('router-link',{attrs:{"to":"/goldrush-v2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Goldrush V2","href":href,"active":isActive,"navigate":navigate,"icon":"fa-ticket-alt"}})]}}],null,false,3149102434)}):_vm._e(),(_vm.canViewOrdersV2)?_c('router-link',{attrs:{"to":"/orders/v2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Orders V2","href":href,"active":isActive,"navigate":navigate,"icon":"fa-dollar-sign"}})]}}],null,false,3545590485)}):(_vm.canViewOrders)?_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Orders","href":href,"active":isActive,"navigate":navigate,"icon":"fa-dollar-sign"}})]}}])}):_vm._e(),(_vm.canViewDonations)?_c('router-link',{attrs:{"to":"/donations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Donations","href":href,"active":isActive,"navigate":navigate,"icon":"fa-donate"}})]}}],null,false,1147633460)}):_vm._e(),(_vm.canViewCustomers)?_c('router-link',{attrs:{"to":"/customers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Customers","href":href,"active":isActive,"navigate":navigate,"icon":"fa-users"}})]}}],null,false,373914047)}):_vm._e(),(_vm.canViewStations)?_c('router-link',{attrs:{"to":"/stations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Stations","href":href,"active":isActive,"navigate":navigate,"icon":"fa-map-marker-alt"}})]}}],null,false,1928863684)}):_vm._e(),(_vm.canViewDevices)?_c('router-link',{attrs:{"to":"/devices"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Devices","href":href,"active":isActive,"navigate":navigate,"icon":"fa-tv"}})]}}],null,false,2078046819)}):_vm._e(),(_vm.showReports)?_c('router-link',{attrs:{"to":"/reports"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Reports","href":href,"active":isActive,"navigate":navigate,"icon":"fa-chart-bar"}})]}}],null,false,853741447)}):_vm._e(),(_vm.showMessageCenter)?_c('router-link',{attrs:{"to":"/message-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Message Center","href":href,"active":isActive,"navigate":navigate,"icon":"fa-envelope"}})]}}],null,false,761351236)}):_vm._e(),(_vm.canViewUsers)?_c('router-link',{attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Users","href":href,"active":isActive,"navigate":navigate,"icon":"fa-users"}})]}}],null,false,2434198698)}):_vm._e(),_c('router-link',{staticClass:"mt-auto",attrs:{"to":_vm.url,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('SidebarButton',{attrs:{"title":"Knowledge Base","href":_vm.url,"active":isActive,"icon":"fa-question-circle","external":"_blank"}})]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-bg sidebar-logo"},[_c('img',{attrs:{"src":require("@/assets/monogram-white.svg"),"width":"42","height":"42","alt":"Rafflebox logo icon"}})])}]

export { render, staticRenderFns }