import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGift,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faGripDotsVertical,
  faPenToSquare,
  faCheck,
  faX,
  faTrashCan,
  faCircleXmark,
  faCircleBolt,
  faCirclePlay,
  faCircleStop,
  faCircleDollar,
  faExclamationCircle,
  faInfoCircle,
  faQuestionCircle,
  faCircleRadiation,
  faMedal,
  faEye,
  faMagnifyingGlass,
  faCopy,
  faArrowDownToLine,
  faBan,
  faFloppyDisk,
  faCircleMinus,
  faIdBadge,
  faUpLong,
  faDownLong,
  faPlus,
  faFileImport,
  faArrowUpFromLine,
  faPaperPlaneTop,
  faHandsHoldingDollar,
  faComments,
  faListTree,
  faDesktop,
  faCircleCheck
} from '@fortawesome/pro-regular-svg-icons';
import { faTimesCircle, faDownload, faCaretDown, faToggleOff, faToggleOn } from '@fortawesome/pro-duotone-svg-icons';
import {
  faTicket,
  faMoneyBillTransfer,
  faRotateRight,
  faReceipt,
  faCheckCircle,
  faTabletScreenButton,
  faHashtag,
  faGrid,
  faTableList,
  faEnvelope,
  faPrint,
  faDownload as faDownloadSolid
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faGift,
  faTimesCircle,
  faDownload,
  faCaretDown,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faGripDotsVertical,
  faPenToSquare,
  faTrashCan,
  faCircleXmark,
  faCircleBolt,
  faCirclePlay,
  faCircleStop,
  faCheckCircle,
  faCheck,
  faX,
  faCircleDollar,
  faExclamationCircle,
  faInfoCircle,
  faQuestionCircle,
  faCircleRadiation,
  faMedal,
  faEye,
  faMagnifyingGlass,
  faCopy,
  faArrowDownToLine,
  faBan,
  faMoneyBillTransfer,
  faTicket,
  faReceipt,
  faRotateRight,
  faTabletScreenButton,
  faHashtag,
  faFloppyDisk,
  faCircleMinus,
  faIdBadge,
  faUpLong,
  faDownLong,
  faGrid,
  faTableList,
  faPlus,
  faFileImport,
  faArrowUpFromLine,
  faToggleOn,
  faToggleOff,
  faPaperPlaneTop,
  faHandsHoldingDollar,
  faComments,
  faListTree,
  faDesktop,
  faEnvelope,
  faPrint,
  faDownloadSolid,
  faCircleCheck
);

export default (vue) => {
  vue.component('FontAwesomeIcon', FontAwesomeIcon);
};
