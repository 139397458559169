<template>
  <div class="wrapper">
    <!-- Sidebar -->
    <nav id="sidebar" :class="{ 'sidebar-bg': !hideNav }">
      <div class="flex flex-col h-100 list-unstyled components" :class="{ 'hide-nav': hideNav }">
        <div class="sidebar-bg sidebar-logo">
          <img src="@/assets/monogram-white.svg" width="42" height="42" alt="Rafflebox logo icon" />
        </div>

        <router-link to="/" v-if="canViewDashboard" v-slot="{ href, navigate, isActive }" exact>
          <SidebarButton
            title="Dashboard"
            :active="isActive"
            :navigate="navigate"
            :href="href"
            icon="fa-tachometer-alt"
          />
        </router-link>

        <router-link to="/admin" v-if="showAdmin" v-slot="{ href, navigate, isActive }" exact>
          <SidebarButton title="Admin" icon="fa-shield-alt" :active="isActive" :navigate="navigate" :href="href" />
        </router-link>

        <router-link to="/raffles" v-if="showRaffles" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Raffles" :href="href" :active="isActive" :navigate="navigate" icon="fa-tags" />
        </router-link>

        <router-link to="/goldrush" v-if="canViewGoldrushRaffles" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Goldrush" :href="href" :active="isActive" :navigate="navigate" icon="fa-ticket-alt" />
        </router-link>

        <router-link to="/goldrush-v2" v-if="canViewGoldrushV2" v-slot="{ href, navigate, isActive }">
          <SidebarButton
            title="Goldrush V2"
            :href="href"
            :active="isActive"
            :navigate="navigate"
            icon="fa-ticket-alt"
          />
        </router-link>

        <router-link to="/orders/v2" v-if="canViewOrdersV2" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Orders V2" :href="href" :active="isActive" :navigate="navigate" icon="fa-dollar-sign" />
        </router-link>
        <router-link to="/orders" v-else-if="canViewOrders" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Orders" :href="href" :active="isActive" :navigate="navigate" icon="fa-dollar-sign" />
        </router-link>
        <router-link to="/donations" v-if="canViewDonations" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Donations" :href="href" :active="isActive" :navigate="navigate" icon="fa-donate" />
        </router-link>
        <router-link to="/customers" v-if="canViewCustomers" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Customers" :href="href" :active="isActive" :navigate="navigate" icon="fa-users" />
        </router-link>

        <router-link to="/stations" v-if="canViewStations" v-slot="{ href, navigate, isActive }">
          <SidebarButton
            title="Stations"
            :href="href"
            :active="isActive"
            :navigate="navigate"
            icon="fa-map-marker-alt"
          />
        </router-link>
        <router-link to="/devices" v-if="canViewDevices" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Devices" :href="href" :active="isActive" :navigate="navigate" icon="fa-tv" />
        </router-link>

        <router-link to="/reports" v-if="showReports" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Reports" :href="href" :active="isActive" :navigate="navigate" icon="fa-chart-bar" />
        </router-link>

        <router-link to="/message-center" v-if="showMessageCenter" v-slot="{ href, navigate, isActive }">
          <SidebarButton
            title="Message Center"
            :href="href"
            :active="isActive"
            :navigate="navigate"
            icon="fa-envelope"
          />
        </router-link>
        <router-link to="/users" v-if="canViewUsers" v-slot="{ href, navigate, isActive }">
          <SidebarButton title="Users" :href="href" :active="isActive" :navigate="navigate" icon="fa-users" />
        </router-link>
        <router-link class="mt-auto" :to="url" v-slot="{ isActive }" custom>
          <SidebarButton
            title="Knowledge Base"
            :href="url"
            :active="isActive"
            icon="fa-question-circle"
            external="_blank"
          />
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
import SidebarButton from '@/components/SidebarButton';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  props: {
    hideNav: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SidebarButton
  },
  data() {
    return {
      url: 'https://help.rafflebox.ca/',
      isRbAdmin: false,
      viewReports: false,
      viewAdmin: false,
      viewRaffles: false,
      viewMessageCenter: false,
      canViewCustomers: false,
      canViewDevices: false,
      canViewOrders: false,
      canViewOrdersV2: false,
      canViewGoldrushRaffles: false,
      canViewGoldrushV2: false,
      canViewDashboard: false,
      canViewUsers: false,
      canViewStations: false,
      canViewDonations: false
    };
  },
  computed: {
    showReports() {
      return this.viewReports;
    },
    showAdmin() {
      return this.viewAdmin;
    },
    showRaffles() {
      return this.viewRaffles;
    },
    showMessageCenter() {
      return this.viewMessageCenter;
    }
  },
  async mounted() {
    this.viewReports = await featureEnabled(LaunchDarklyKeys.ViewReports);
    this.viewAdmin = await featureEnabled(LaunchDarklyKeys.Admin);
    this.viewRaffles = await featureEnabled(LaunchDarklyKeys.ViewRaffles);
    this.viewMessageCenter = await featureEnabled(LaunchDarklyKeys.MessageCenter);
    this.canViewCustomers = await featureEnabled(LaunchDarklyKeys.ViewCustomers);
    this.canViewDevices = await featureEnabled(LaunchDarklyKeys.ViewDevices);
    this.canViewOrders = await featureEnabled(LaunchDarklyKeys.ViewOrders);
    this.canViewOrdersV2 = await featureEnabled(LaunchDarklyKeys.OrdersV2Beta);
    this.canViewGoldrushRaffles = await featureEnabled(LaunchDarklyKeys.ViewGoldrushRaffles);
    this.canViewGoldrushV2 = await featureEnabled(LaunchDarklyKeys.ViewGoldrushV2);
    this.canViewUsers = await featureEnabled(LaunchDarklyKeys.ViewUsers);
    this.canViewStations = await featureEnabled(LaunchDarklyKeys.ViewStations);
    this.canViewDashboard = await featureEnabled(LaunchDarklyKeys.ViewDashboard);
    this.canViewDonations = await featureEnabled(LaunchDarklyKeys.ViewDonations);
  },
  async created() {
    this.isRbAdmin = this.$store.state.isRbAdmin;
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: none;
  width: 100%;

  @media (min-width: 62em) {
    display: flex;
  }
}

#sidebar {
  width: 100px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  color: #fff;
  overflow-y: auto;
}

.sidebar-logo {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-bottom: 1px solid rgba(10, 10, 10, 0.13);
}

.sidebar-bg {
  background-color: #5db966;
  border-right: 1px solid rgba(10, 10, 10, 0.13);
}

.hide-nav {
  border-right: 0;
}

.hide-nav li {
  display: none;
}
</style>
