<template>
  <b-form-group
    label-for="input-license-upload"
    :label-size="smallField ? 'sm' : ''"
    :invalid-feedback="veeErrors.first('input-license-upload')"
    class="input-license-upload"
  >
    <template slot="label" class="mb-0">
      Raffle License Upload
      <span>
        <sup
          v-b-tooltip.hover.top="
            'If you do not have a license you can provide it later. It will be required before your Raffle is made live.'
          "
        >
          <span class="pl-2 fas fa-info-circle"></span>
        </sup>
      </span>
    </template>
    <div class="center-spinner">
      <b-form-file
        name="input-license-upload"
        v-model="licenseFile"
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
        v-validate="{}"
        :state="validateLicenseUpload('input-license-upload')"
        :size="smallField ? 'sm' : ''"
        :disabled="disabled"
        @input="renameLicenseFile"
        no-drop
      ></b-form-file>
      <b-spinner v-if="isUploading" label="Uploading File" variant="secondary" class="ml-2"></b-spinner>
      <b-form-invalid-feedback> The Licence Number field must be filled in.</b-form-invalid-feedback>
    </div>

    <p class="mt-1 mb-2" v-if="licenseUrl && showDownloadLink">
      <span class="d-block">
        <b-link :href="licenseUrl" target="top" class="text-sm">
          Current License
          <span class="pl-1"><FontAwesomeIcon :icon="['far', 'arrow-down-to-line']" /></span>
        </b-link>
      </span>
    </p>
    <div class="text-sm" v-if="licenseUrl">
      {{ this.uploadFileTime }}
    </div>
  </b-form-group>
</template>

<script>
import EventServiceV2 from '@/lib/event-service-v2';
import { formatDateTime } from '@rafflebox-technologies-inc/rafflebox-lib';

export default {
  props: {
    licenseNumber: {
      type: String,
      required: true
    },
    organizationId: {
      type: String,
      required: true
    },
    licenseUrl: {
      type: String
    },
    smallField: {
      type: Boolean,
      default: false
    },
    showDownloadLink: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      licenseFile: null,
      licenseName: null,
      isUploading: false,
      uploadFileTime: null
    };
  },
  mounted() {
    // retrieve the last update time from the licenseUrl after upload
    this.uploadFileTime = this.retrieveLastUpdate();
  },

  methods: {
    validateLicenseUpload(ref) {
      if (this.veeFields[ref] && this.veeFields[ref].dirty) {
        return this.licenseNumber !== null && this.licenseFile !== null;
      }
      return null;
    },
    async renameLicenseFile(uploadedFile) {
      if (this.licenseNumber) {
        const ext = uploadedFile.name.split('.').pop();
        this.licenseName = this.licenseNumber + '.' + ext;

        this.uploadFileTime = formatDateTime(new Date(), {
          format: 'dateTime',
          timeZone: this.sessionUserTimeZone,
          displayTimeZone: true
        });

        const originalFile = uploadedFile;
        const file = new File([originalFile], this.licenseName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified
        });

        this.licenseFile = file;
        if (this.licenseNumber !== null && this.licenseNumber.length >= 5) {
          await this.uploadFile();
        }
      }
    },
    async createLicenseUrl() {
      try {
        const params = {
          organizationId: this.organizationId,
          licenseNum: this.licenseNumber,
          fileName: `${this.licenseName}`
        };
        const url = await EventServiceV2.getLicenseUrl(params);

        return url;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    async uploadFile() {
      try {
        this.isUploading = true;
        const licenseUrl = await this.createLicenseUrl();
        await EventServiceV2.uploadFileToS3(licenseUrl, this.licenseFile);

        const formattedLicenseUrl = this.formatLicenseUrl(licenseUrl);
        this.$emit('setLicenseUrl', formattedLicenseUrl);
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      } finally {
        this.isUploading = false;
      }
    },
    formatLicenseUrl(url) {
      const stripS3Url = url.split('.com/').pop();
      const stripAwsQueryParams = stripS3Url.split('?').shift();
      return `https://${stripAwsQueryParams}`;
    },
    retrieveLastUpdate() {
      if (this.licenseUrl) {
        // create a new URL object
        const urlObj = new URL(this.licenseUrl);
        const path = urlObj.pathname;
        const pathParts = path.split('/');
        const findDateIndex = pathParts.findIndex((part) => /\d{4}-\d{2}-\d{2}/.test(part));

        // true
        if (findDateIndex !== -1) {
          const extractedDate = pathParts[findDateIndex];

          return formatDateTime(extractedDate.replace(/%3A/g, ':'), {
            format: 'dateTime',
            timeZone: this.sessionUserTimeZone,
            displayTimeZone: true
          });
        }
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-file {
  display: inline-block;
  max-width: calc(100% - 3rem);
}

.center-spinner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.input-license-upload {
  label {
    margin-bottom: 0 !important;
  }

  .custom-file-label {
    border-radius: 8px;
    min-width: 100%;
  }
}
</style>
