import { initialize } from 'launchdarkly-js-client-sdk';
import config from '../config';

/**
 * LaunchDarkly client is used to enable/disable features based on user properties.
 *
 * See: {@link https://docs.launchdarkly.com/sdk/client-side/javascript}
 */

let launchDarklyClient;
let readyPromise;

export const LaunchDarklyKeys = {
  // default on check
  Default: 'launch-darkly-enabled',
  // developer override for all features
  GodMode: 'god-mode',
  // add other feature keys here
  CashDrop: 'enable-cash-drop',
  SalesBySeller: 'enable-sales-by-seller',
  ImportOrders: 'enable-import-order',
  EditRaffle: 'edit-raffle',
  QuickbooksInvoices: 'quickbook-invoices',
  TopSellersReport: 'top-seller-report',
  ViewReports: 'view-reports',
  ManageDomains: 'manage-domains',
  Admin: 'admin',
  StartARafflebox: 'start-a-rafflebox',
  ViewRaffles: 'view-raffles',
  EditRaffleTicker: 'edit-raffle-ticker',
  CreateCashSales: 'create-cash-sales',
  MessageCenter: 'message-center',
  EditMessageCenter: 'edit-message-center',
  OrdersV2Beta: 'orders-v-2',
  EditRaffleTags: 'edit-raffle-tags',
  ViewGoldrushRaffles: 'view-goldrush-raffles',
  ViewSystemInfo: 'view-system-info',
  CreateRaffle: 'create-raffle',
  CopyRaffle: 'copy-raffle',
  ChangeOrganization: 'change-organization',
  DeactivateRaffle: 'deactivate-raffle',
  EditBilling: 'edit-billing',
  ViewCustomers: 'view-customers',
  ViewDevices: 'view-devices',
  ViewOrders: 'view-orders',
  DownloadTickets: 'download-tickets',
  ActivateRaffle: 'activate-raffle',
  ViewGoldrushV2: 'view-goldrush-v-2',
  SetTicketLimit: 'set-ticket-limit',
  CreateOrganization: 'create-organization',
  ActivateAllEventMembers: 'activate-all-event-members',
  DeleteRaffle: 'delete-raffle',
  EditCustomer: 'edit-customer',
  EditPayoutSchedule: 'edit-payout-schedule',
  PrintTickets: 'print-tickets',
  VoidOrdersV2: 'void-orders-v-2',
  EditRaffleEventMembers: 'edit-raffle-event-members',
  EditRaffleAdvanced: 'edit-raffle-advanced',
  EditTicketPackages: 'edit-ticket-packages',
  EditRaffleFees: 'edit-raffle-fees',
  EditRaffleFeesTab: 'edit-raffle-fees-tab',
  EditRaffleEmail: 'edit-raffle-email',
  EditRaffleInVenue: 'edit-raffle-in-venue',
  EditRaffleSalesRep: 'edit-raffle-sales-rep',
  EmailOrder: 'email-order',
  EndRaffle: 'end-raffle',
  EditRaffleProcessingFees: 'edit-raffle-processing-fees',
  ImportEventMembers: 'import-event-members',
  GoldrushSales: 'goldrush-sales',
  InVenueImprovements: 'in-venue-improvements',
  PrintOrder: 'print-order',
  AdminInvoices: 'admin-invoices',
  AdminOrganizations: 'admin-organizations',
  AdminOrders: 'admin-orders',
  AdminUsers: 'admin-users',
  AdminRaffles: 'admin-raffles',
  AdminDevices: 'admin-devices',
  AdminEditOrganization: 'admin-edit-organization',
  ViewAccount: 'view-account',
  AccountStripe: 'account-stripe',
  AccountPayouts: 'account-payouts',
  AccountInvoices: 'account-invoices',
  AccountValidateInvoice: 'account-validate-invoice',
  AccountAuthorizeInvoice: 'account-authorize-invoice',
  AccountPayInvoice: 'account-pay-invoice',
  AccountDeleteInvoice: 'account-delete-invoice',
  AccountEditInvoice: 'account-edit-invoice',
  AccountRaffleboxPayouts: 'account-rafflebox-payouts',
  ViewUsers: 'view-users',
  ViewTickets: 'view-tickets',
  SendTestOrderEmail: 'send-test-order-email',
  ViewDashboard: 'view-dashboard',
  EditRaffleRBAdminSettings: 'edit-raffle-rb-admin-settings',
  RequestRaffleActivation: 'request-raffle-activation',
  PrizeDrawsNewPrizeFlow: 'prize-draws-new-prize-flow',
  PrizeDrawsPrizeControls: 'prize-draws-prize-controls',
  ViewStations: 'view-stations',
  EditUserDetails: 'edit-user-details',
  EditRaffleRestricted: 'edit-raffle-restricted',
  GoldrushStationFilter: 'goldrush-station-filter',
  EventMemberV2: 'event-member-v-2',
  ImportCustomers: 'import-customers',
  ExportCustomers: 'export-customers',
  InboundCheckout: 'inbound-checkout',
  ViewDonations: 'view-donations',
  ProcessBatchOrders: 'process-batch-orders',
  CreateOrderV2: 'create-order-v2',
  EditOrderV2: 'edit-order-v-2',
  BulkPrizes: 'bulk-prizes',
  EditUserForMNPRoles: 'edit-user-for-mnp-roles',
  RaffleSalesNew: 'raffle-sales-new',
  RaffleSalesOld: 'raffle-sales-old'
};

export const initLaunchDarkly = async (user) => {
  // eslint-disable-next-line
  console.log('[LaunchDarkly] Initializing LaunchDarkly client');

  launchDarklyClient = initialize(config.LD_CLIENT, {
    kind: 'user',
    key: user.id, // this is actually uuid
    name: user.name,
    email: user.email,
    organizationUuid: user.organizationUuid,
    roles: user.roles
  });

  return new Promise((resolve) => {
    launchDarklyClient.on('ready', () => {
      // eslint-disable-next-line
      console.log('LaunchDarkly client is ready');
      resolve();
    });
  });
};

export const featureEnabled = async (key, defaultValue = false) => {
  if (!launchDarklyClient) {
    // eslint-disable-next-line
    console.warn('[LaunchDarkly] Client is not initialized');
    return defaultValue;
  }

  await readyPromise;

  const godModeEnabled = await launchDarklyClient.variation(LaunchDarklyKeys.GodMode, false);

  if (godModeEnabled) {
    return true;
  }

  return await launchDarklyClient.variation(key, defaultValue);
};
