<template>
  <div v-if="!busy" id="app">
    <component class="page-fade-in" :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import { checkEnv } from './lib/enable-feature';
import store from '@/store';
import config from '@/config';
import { initLaunchDarkly, featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  metaInfo: {
    title: 'Rafflebox Dashboard'
  },
  data() {
    return {
      busy: true
    };
  },
  async created() {
    const sessionUser = await getAuth().sessionUser();

    if (sessionUser) {
      await store.dispatch('setOrganization', sessionUser.organizationUuid);
      await store.dispatch('setEvents');
      await initLaunchDarkly(sessionUser);

      // Add heap conditionaly depending on user
      if (config.HEAP_ID && !this.$store.state.isRbAdmin) {
        console.log('Heap Enabled');
        (window.heap = window.heap || []),
          // eslint-disable-next-line no-var, no-undef
          (heap.load = function (e, t) {
            (window.heap.appid = e), (window.heap.config = t = t || {});
            // eslint-disable-next-line no-var, no-undef
            var r = document.createElement('script');
            (r.type = 'text/javascript'),
              (r.async = !0),
              (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
            // eslint-disable-next-line no-var, no-undef
            var a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(r, a);
            for (
              // eslint-disable-next-line no-var, no-undef
              var n = function (e) {
                  return function () {
                    // eslint-disable-next-line no-var, no-undef, prefer-rest-params
                    heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                  };
                },
                p = [
                  'addEventProperties',
                  'addUserProperties',
                  'clearEventProperties',
                  'identify',
                  'resetIdentity',
                  'removeEventProperty',
                  'setEventProperties',
                  'track',
                  'unsetEventProperty'
                ],
                o = 0;
              o < p.length;
              o++
            )
              // eslint-disable-next-line no-var, no-undef
              heap[p[o]] = n(p[o]);
          });
        // eslint-disable-next-line no-var, no-undef
        heap.load(config.HEAP_ID);
      }

      // Heap User Identity Tracking
      if (window.heap && !this.$store.state.isRbAdmin) {
        const userIdentity = `${sessionUser.organizationName} - (${sessionUser.email})`;
        const customProperties = {
          Name: sessionUser.name,
          Organization: sessionUser.organizationName,
          OrganizationId: sessionUser.organizationUuid
        };

        window.heap.identify(userIdentity);
        window.heap.addUserProperties(customProperties);
      }

      if (checkEnv('Onboarding')) {
        await store.dispatch('setOnboarding');

        if (await store.state.displayOnboarding) {
          this.$router.push({ name: 'Onboarding' });
        }
      }

      const launchDarklyEnabled = await featureEnabled(LaunchDarklyKeys.Default);
      // eslint-disable-next-line
      console.log('launchDarklyEnabled', launchDarklyEnabled);
    }

    this.busy = false;
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'blank';
    }
  }
};
</script>
<style>
@import url('https://use.typekit.net/tlb6hrw.css');

html,
body {
  font-family: 'Roboto', sans-serif;
}

.container {
  text-align: left;
  margin-top: 1.875rem;
  margin-left: 7rem;
}

@media screen and (max-width: 62em) {
  .container {
    margin-left: auto;
  }
}

.table-overflow {
  display: block;
  max-width: 100%;
  margin-bottom: 2rem;
  overflow-x: scroll;
}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1 {
  font-family: 'futura-pt', 'Roboto', sans-serif;
  padding-bottom: 1rem;
  font-size: 2rem;
}

h2 {
  padding-bottom: 1rem;
  font-size: 1.5rem;
}

.page-fade-in {
  animation: fadeIn 0.1s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
