<template>
  <div
    class="relative flex items-center mb-4 py-6 pl-8 pr-6 border border-l-8 bg-white rounded-lg shadow-sm text-left"
    :class="`${variantClass} ${iconClass} ${noIconMargin}`"
  >
    <div class="absolute flex-initial -left-7 bg-white rounded-full empty:hidden" :class="iconColor">
      <FontAwesomeIcon v-if="iconType !== null" :icon="iconType" size="3x" />
    </div>
    <div class="rb-alert__msg">
      <div class="text-xl font-bold empty:hidden">
        <slot name="heading"></slot>
      </div>
      <slot></slot>
      <div class="font-mono leading-tight rb-alert__pre">
        <slot name="error"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    icon: {
      type: String,
      default: null,
      validator: (value) => ['check', 'times', 'exclamation', 'info', 'question', 'radiation'].includes(value)
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'green', 'red', 'blue', 'yellow', 'purple', 'inaccessible'].includes(value)
    }
  },
  computed: {
    variantClass() {
      switch (this.variant) {
        case 'green':
          return 'border-green-600';
        case 'red':
          return 'border-red-600';
        case 'yellow':
          return 'border-yellow-600';
        case 'purple':
          return 'border-purple-600';
        case 'blue':
          return 'border-blue-600';
        case 'inaccessible':
          return 'text-gray-300 border-gray-300';
        default:
          return 'border-gray-500';
      }
    },
    noIconMargin() {
      return this.icon ? 'ml-5' : '';
    },
    iconClass() {
      return this.icon ? 'ml-[1.2rem]' : '';
    },
    iconType() {
      switch (this.icon) {
        case 'check':
          return ['far', 'circle-check'];
        case 'times':
          return ['far', 'circle-xmark'];
        case 'exclamation':
          return ['far', 'circle-exclamation'];
        case 'info':
          return ['far', 'circle-info'];
        case 'question':
          return ['far', 'circle-question'];
        case 'radiation':
          return ['far', 'circle-radiation'];
        default:
          return null;
      }
    },
    iconColor() {
      switch (this.variant) {
        case 'green':
          return 'text-green-600';
        case 'red':
          return 'text-red-600';
        case 'yellow':
          return 'text-yellow-600';
        case 'purple':
          return 'text-purple-600';
        case 'blue':
          return 'text-blue-600';
        case 'inaccessible':
          return 'text-gray-300';
        default:
          return 'text-gray-500';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
