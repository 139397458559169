<template>
  <div>
    <b-form-group label-for="event-banner-question">
      <b-form-checkbox v-model="willUseInSupportOf" name="will-use-in-support-of" switch class="mb-4">
        <span class="pr-1">Use "In Support Of" dropdown on raffle page</span>
        <sup id="will-use-in-support-of-tooltip">
          <span class="fas fa-info-circle"></span>
        </sup>
        <b-tooltip target="will-use-in-support-of-tooltip" triggers="hover focus">
          <a href="https://www.youtube.com/watch?v=0JRAFpVD_F0" class="underline" target="_blank" rel="noreferrer"
            >What is this?</a
          >
        </b-tooltip>
      </b-form-checkbox>

      <Alert v-if="willUseInSupportOf === true" variant="blue" icon="info">
        After this Request New Raffle form has been submitted, the Rafflebox support team will contact you to provide
        your member list for import.
      </Alert>
    </b-form-group>
  </div>
</template>
<script>
import Alert from '@/components/ui/Alert';

export default {
  components: {
    Alert
  },
  data() {
    return {
      willUseInSupportOf: false
    };
  }
};
</script>
